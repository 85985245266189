import React, { useEffect } from "react";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import def_user from "../assets/def_user.webp";
import { auth, uiConfig } from "../controllers/firebase";
import { TbLogout } from "react-icons/tb";

const AuthGoogle = ({ currentUser } = currentUser) => {
  const handleLogout = () => {
    auth.signOut();
  };

  useEffect(() => {
    const ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    ui.start("#firebaseui-auth-container", uiConfig);
  }, [auth, currentUser]);

  return (
    <>
      <div
        className={`flex items-center ml-auto mr-4 relative group ${
          currentUser == null ? "hidden" : ""
        }`}
      >
        <img
          className="w-10 h-10 rounded-full"
          src={currentUser?.photoURL}
          onError={(e) => {
            e.target.src = def_user;
          }}
        />
        <div className="hidden group-hover:block bg-gray-900 text-white text-xs font-bold rounded-lg px-2 py-1 absolute transform translate-y-10">
          {currentUser?.displayName}
        </div>
      </div>
      <button
        onClick={() => {
          handleLogout();
        }}
        className={`${
          currentUser == null ? "hidden" : ""
        } flex self-center mr-2 sm:mr-4 bg-primary-800 bg-opacity-20 border-2 border-primary-800 hover:bg-primary-800 text-white font-bold p-1 sm:py-2 sm:px-4 rounded`}
      >
        <span className="hidden sm:inline">Cerrar sesión</span>
        <TbLogout className="m-1 sm:ml-3" />
      </button>
      <div
        className={`self-center ml-auto sm:mr-4 ${
          currentUser == null ? "" : "hidden"
        }`}
        id="firebaseui-auth-container"
      />
    </>
  );
};

export default AuthGoogle;
