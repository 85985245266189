import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import ProgressBar from "@ramonak/react-progress-bar";

const FileDropArea = ({ setFile, file, progress, error }) => {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const aFile = acceptedFiles[0];
      setFile(aFile);
    },
    [setFile]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={`w-5/6 sm:w-full self-center sm:self-auto sm:max-h-[60vh] flex sm:overflow-hidden sm:mx-8 mb-4 sm:my-4 sm:mb-4 sm:mr-10 rounded-xl shadow-lg bg-gray-700 items-center justify-center p-4 sm:p-8 font-medium cursor-pointer hover:scale-[1.01] transition-transform ${
        isDragActive && "bg-primary-800 opacity-70 animate-pulse"
      }`}
    >
      <input {...getInputProps()} />
      {progress > 0 && progress < 100 ? (
        <ProgressBar
          completed={progress}
          bgColor="#bb00cf"
          labelAlignment="center"
          baseBgColor="#4f545c"
          labelColor="#e80909"
          maxCompleted={100}
          customLabel=" "
          className="w-9/12"
        />
      ) : file ? (
        <div>
          <p className="text-lg text-gray-500">
            Archivo: <span className="text-sm font-normal">{file.name}</span>{" "}
          </p>
          <p className="text-2xl text-gray-500">
            Rellena la información y presiona{" "}
            <span className="text-primary-700 font-medium">"Enviar"</span>
          </p>
        </div>
      ) : error ? (
        <p>Ha ocurrido un error, intenta de nuevo</p>
      ) : (
        <div
          className={`border-dashed border-2 m-2 sm:m-0 rounded-xl w-full h-full items-center justify-center flex text-2xl ${
            isDragActive
              ? "border-gray-500 text-gray-500"
              : "border-gray-600 text-gray-600"
          }`}
        >
          <p className="hidden sm:inline">Arrastra el clip aquí</p>
          <p className="sm:hidden">Selecciona el clip</p>
        </div>
      )}
    </div>
  );
};

export default FileDropArea;
