import React from "react";
import { HiOutlineBackward } from "react-icons/hi2";
import AuthGoogle from "./AuthGoogle";

const Header = ({ currentUser }) => {
  return (
    <header className="fixed flex flex-row bg-gray-900 w-full shadow z-20 h-16">
      <a
        className="flex items-center ml-8 cursor-pointer"
        href="https://nomolandia.es"
      >
        <h1 className="text-2xl font-[Poppins] font-light text-primary-600">
          Nomo
        </h1>
        <h1 className="text-2xl font-[Poppins] font-bold">Landia</h1>
      </a>
      <div className="flex items-center group cursor-default">
        <HiOutlineBackward className="text-4xl ml-3 bg-primary-800 bg-opacity-70 border-2 border-primary-800 group-hover:bg-primary-800 rounded-lg p-1 shadow-[2px_2px_6px_0px_rgba(154,0,169,0.4)] group-hover:animate-spin duration-200" />
        <span class="rotate-12 -translate-x-2 -translate-y-2.5 bg-gray-800 text-xs font-medium mb-2 px-1 py-0.5 rounded border border-gray-600">
          2023
        </span>
      </div>
      <AuthGoogle currentUser={currentUser} />
    </header>
  );
};

export default Header;
