import React, { useEffect, useState } from "react";
import { getClips } from "../controllers/clip";
import ClipItem from "./ClipItem";

const ClipList = ({ setSelectedVideo, selectedVideo, userEmail }) => {
  const [clips, setLinks] = useState([]);

  useEffect(() => {
    if (userEmail) {
      getClips(setLinks, userEmail);
    } else {
      setLinks([]);
    }
  }, [userEmail]);

  const handleClick = (video) => {
    setSelectedVideo(video);
  };

  return (
    <nav
      className={`bg-gradient-to-t sm:pr-[28rem] w-full h-48 from-gray-900 via-gray-900 to-transparent fixed bottom-0 inset-x-0 flex flex-col sm:flex-row items-center sm:space-x-1 ${
        selectedVideo ? "hidden" : ""
      }`}
    >
      <h2 className="sm:text-lg shrink-0 font-semibold self-start my-2 sm:my-6 ml-4 sm:ml-8 sm:mr-4">
        Tus clips
      </h2>
      <div className="w-full">
        <ul className="flex flex-row gap-3 mb-5 overflow-x-auto scrollbar2">
          {!clips || clips.length === 0 ? (
            <p className="text-gray-500 bg-transparent border-2 border-gray-800 bg-opacity-40 rounded-lg shadow-md text-xl w-full h-28 mx-20 flex items-center justify-center">
              No has subido nada 🤨
            </p>
          ) : (
            clips.map((clip, i) => (
              <ClipItem key={i} clip={clip} handleClick={handleClick} />
            ))
          )}
        </ul>
      </div>
    </nav>
  );
};

export default ClipList;