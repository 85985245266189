import React from "react";
import def_thumbnail from "../assets/def_thumbnail.webp";

const ClipItem = ({ clip, handleClick }) => {
  return (
    <li
      className="flex flex-col flex-shrink-0 relative bg-gray-800 rounded-lg cursor-pointer shadow-md p-2"
      onClick={() => handleClick(clip)}
    >
      <img
        src={clip.thumbnail}
        onError={(e) => (e.target.src = def_thumbnail)}
        alt="Clip thumbnail"
        className="max-h-20 lg:max-h-28 min-w-max rounded-lg mb-2 self-center"
      ></img>
      <div className="flex-1 relative max-h-9 overflow-hidden truncate">
        <h4 className="text-sm">{clip.title}</h4>
        <h5 className="text-xs font-light">{clip.game}</h5>
      </div>
    </li>
  );
};

export default ClipItem;
