import React, { useState, useCallback } from "react";
import { FaPaperPlane } from "react-icons/fa";
import { addClip } from "../controllers/clip";
import { getUsername } from "../controllers/user";

const FileOptions = ({ setFile, file, userEmail, setProgress, setError }) => {
  const initialState = { title: "", game: "", notes: "", user: "" };
  const [info, setInfo] = useState(initialState);

  const handleUpload = useCallback(() => {
    addClip(file, info, setProgress, setError);
  }, [file, info, setProgress, setError]);

  const handleInputChange = (e) => {
    e.target.classList.remove("border-red-500");
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value, user: getUsername(userEmail) });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (info.title && info.game) {
      handleUpload();
      setInfo(initialState);
      setFile(null);
    } else {
      // Set focus on the first input and in the select if it's empty
      if (!info.title) {
        document.getElementById("title").classList.add("border-red-500");
      }
      if (!info.game) {
        document.getElementById("game").classList.add("border-red-500");
      }
    }
  };

  return (
    <form className="sm:fixed right-0 sm:h-full sm:max-h-[65vh] w-11/12 sm:w-96 self-center sm:self-auto sm:mr-4 p-1 sm:overflow-auto bg-gray-700 rounded-lg shadow-sm flex flex-col gap-6 scrollbar z-10">
      <label className="mt-4 mx-6">
        Título
        <input
          className="block mt-1 w-full py-2 px-3 bg-gray-600 rounded-md outline-none shadow-sm focus:shadow-md transition-shadow"
          type="text"
          name="title"
          value={info.title}
          onChange={handleInputChange}
          onInvalid={(e) => {
            e.target.setCustomValidity("Por favor, ingresa un título");
          }}
          onInput={(e) => {
            e.target.setCustomValidity("");
          }}
          id="title"
          required
        />
      </label>
      <label className="mx-6">
        Juego
        <select
          className="form-select mt-1 w-full cursor-pointer py-2 px-3 bg-gray-600 rounded-md outline-none shadow-sm focus:shadow-md transition-shadow"
          name="game"
          value={info.game}
          onChange={handleInputChange}
          id="game"
        >
          <option value=""></option>
          <option value="ARK">ARK</option>
          <option value="CS:GO">CS:GO</option>
          <option value="CS2">CS2</option>
          <option value="Valorant">Valorant</option>
          <option value="CoN">CoN</option>
          <option value="Fortnite">Fortnite</option>
          <option value="Genshin">Genshin</option>
          <option value="GTA">GTA</option>
          <option value="Minecraft">Minecraft</option>
          <option value="SoT">SoT</option>
          <option value="Rocket">Rocket</option>
          <option value="Star Citizen">Star Citizen</option>
          <option value="IRL">IRL</option>
          <option value="Otros">Otros</option>
        </select>
      </label>
      <div className="mx-6 sm:mt-auto mb-6">
        <label className="text-white">
          Notas{" "}
          <span className="text-gray-400 text-xs font-normal">(Opcional)</span>
          <textarea
            className="form-input block mt-1 w-full py-2 px-3 bg-gray-600 rounded-md outline-none shadow-sm focus:shadow-md transition-shadow"
            name="notes"
            value={info.notes}
            onChange={handleInputChange}
          />
        </label>
        <button
          className="flex gap-2 items-center justify-center py-2 px-3 bg-primary-800 shadow-sm rounded-md bg-opacity-20 border-2 border-primary-800 hover:bg-primary-800 hover:shadow-md duration-150 font-semibold mt-5"
          type="submit"
          onClick={handleSubmit}
        >
          <FaPaperPlane />
          Enviar
        </button>
      </div>
    </form>
  );
};

export default FileOptions;
